import './App.css';
import VideoIframe from "./VideoFrame";

function App() {

    const videoId = "qKQmF134nEw";
    const videoTitle = "Gundam Seed Freedom";

  return (
    <div className="App">
        <div className={'logo'}>
            <img src={"./imgs/Gundam-Seed-logo.png"}/>
        </div>
      {/*<iframe width="560" height="315" src="https://www.youtube.com/embed/qKQmF134nEw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>*/}
        <VideoIframe videoId={videoId} videoTitle={videoTitle} />
        <div className={"bandai-logo"}>
            <img src={"./imgs/Logo_Bandai.svg.png"}/>
        </div>
        <div className={'bg'}>
            <img src={"./imgs/cockpit2.png"}/>
        </div>
        <div className={"cc"}>
            ©SOTSU・SUNRISE ©SOTSU・SUNRISE・MBS ©SOTSU・SUNRISE・TV TOKYO
            Copyright ©Bandai Namco Filmworks Inc. All rights reserved.
        </div>
    </div>
  );
}

export default App;
